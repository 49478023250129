import { useEffect, useState } from 'react';
import { IoPeopleOutline } from "react-icons/io5";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { LuckyModalFormData, ModalHeadProps } from '../LuckyModal';
import { Button } from 'day8-ui';

type CrewProps = {
  formData: LuckyModalFormData
  handleChange: (e: any) => void
  setModalHeadProps: (props: ModalHeadProps | null) => void
}
const CREW = {
  MAX_GUESTS: 14
};

const Crew: React.FC<CrewProps> = ({ formData, handleChange, setModalHeadProps }) => {
  const [capacity, setCapacity] = useState(formData.capacity);
  useEffect(() => {
    setModalHeadProps({
      icon: <IoPeopleOutline />,
      title: "Who?",
      subtitle: "Whether there's two of you or a whole group, let us know so we can show the right options for you.",
    })
  }, [])
  useEffect(() => {
    if (capacity !== formData.capacity) {
      handleChange({ target: { value: capacity, name: 'capacity' } });
    }
  }, [capacity, handleChange]);
  return (
    <div className="dui-flex dui-justify-between dui-items-center dui-w-full dui-mb-4">
      <div>
        <p className="">Capacity</p>
      </div>
      <div className="dui-flex dui-items-center dui-gap-4">
        <Button buttonType="SECONDARY" buttonSize="EXTRA_SMALL" rightIcon={<FaMinus />} onClick={() => setCapacity(capacity - 1)} disabled={capacity === 0} />
        <span className='dui-w-4 dui-text-center'>{capacity}</span>
        <Button buttonType="SECONDARY" buttonSize="EXTRA_SMALL" rightIcon={<FaPlus />} onClick={() => setCapacity(capacity + 1)} disabled={capacity >= CREW.MAX_GUESTS} />
      </div>
    </div>
  )
};

export default Crew;